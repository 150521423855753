body {
  background-color: white;
  color: black;
}

.team {
  background-image: url('../public/team.png');
  height: 38rem;
  background-size: cover;
}

.hide {
  display: none;
}

tr.activeRow td {
  background: yellow;
}

.signature1 {
  font-family: "Marck Script", cursive;
  font-weight: 400;
  font-style: italic;
  text-transform: capitalize;
  text-align: left;
  font-size: 1.5rem;
  text-align: center;
}

.cover {
  background: #ffffff94;
  height: 100%;
}

.text-top {
  margin-top: 10%;
}

.code {
  text-align: center;
  width: 100%;
  display: block;
  background: lawngreen;
  margin: 1%;
  padding: 1%;
}

.tableFixHead {
  overflow-y: auto;
  /* make the table scrollable if height is more than 200 px  */
}

.tableFixHead td,
.tableFixHead tr {
  height: 3em;
}

.fixHeight {
  height: 30rem;
}

.tableFixHead thead th {
  position: sticky;
  /* make the table heads sticky */
  width: 100px;
  top: 0px;
  /* table head will be placed from the top of the table and sticks to it */
}

.tableFixHead .small {
  width: 6%;
}

.tableFixHead .large {
  width: 30%;
}

.w-m {
  width: 80px;
}

.tableFixHead tbody td,
.elips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  /* make the table borders collapse to each other */
  width: 100%;
}

.pipelineForm button {
  display: inline-block;
  margin: 0px 10px;
  padding: 6px 0px;
  vertical-align: baseline;
  width: 80px;
}

.form-select.small {
  margin-left: 1%;
  display: inline-block;
  width: 12%;
}

.menu {
  width: 100px;
  padding: 0;
}

.reload {
  width: 42px;
  border-radius: 14px;
  float: inline-end;
  cursor: pointer;
}

.nav-item {
  background: #d7ecff;
  border-radius: 3px;
}

.gridItem:hover {
  padding: 0%;
  border-radius: 10px;
  box-shadow: 0 0 15px 10px rgb(166, 162, 162);
}

.nav-pills .selected {
  background-color: aquamarine;
}

.title {
  font-weight: bold;
  background-color: bisque;
}

.tbl {
  overflow-y: scroll;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  display: table-row-group;
}

.basic-multi-select {
  margin: 1%;
  width: 80%;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header {
  text-transform: capitalize;
}

.sublabel {
  display: inline-block;
  color: gray;
  font-style: italic;
  font-size: 80%;
  margin: 0;
  margin-left: 4px;
}

.expenseRow {
  cursor: pointer;
}

.selected td,
.expenseRow:hover td {
  background: yellow;
}

#expenseReport {
  table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    unicode-bidi: isolate;
    line-height: normal;
    font-weight: normal;
    font-size: medium;
    font-style: normal;
    color: -internal-quirk-inherit;
    text-align: start;
    border-spacing: 2px;
    border-color: gray;
    white-space: normal;
    font-variant: normal;
  }

  thead {
    display: table-header-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    unicode-bidi: isolate;
    border-color: inherit;
  }

  th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: -internal-center;
    unicode-bidi: isolate;
  }

  #toDownload {
    width: 1024px;
    margin: auto;
    padding: 5%;
    text-align: center;
    border: 1px solid black;
  }

  .waffle {
    width: 100%;
  }

  .ritz .waffle a {
    color: inherit;
  }

  .ritz .waffle .s9 {
    border-bottom: 2px SOLID #7f7f7f;
    background-color: #ffffff;
    text-align: left;
    color: #000000;
    font-family: docs-Tahoma, Arial;
    font-size: 10pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s0 {
    background-color: #ffffff;
    text-align: left;
    color: #000000;
    font-family: docs-Tahoma, Arial;
    font-size: 10pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s3 {
    background-color: #ffffff;
    text-align: center;
    font-weight: bold;
    color: #000000;
    font-family: docs-Tahoma, Arial;
    font-size: 14pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s7 {
    border-bottom: 1px SOLID #000000;
    border-right: 1px SOLID #000000;
    background-color: #eee3db;
    text-align: center;
    color: #000000;
    font-family: docs-Tahoma, Arial;
    font-size: 10pt;
    vertical-align: middle;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s4 {
    border-bottom: 1px SOLID #000000;
    border-right: 1px SOLID #000000;
    background-color: #2a2a2a;
    text-align: center;
    font-weight: bold;
    color: #eee3db;
    font-family: docs-Tahoma, Arial;
    font-size: 10pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s17 {
    border-bottom: 1px SOLID #000000;
    background-color: #ffffff;
    text-align: left;
    font-weight: bold;
    color: #000000;
    font-family: docs-Calibri, Arial;
    font-size: 10pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s6 {
    border-bottom: 1px SOLID #000000;
    border-right: 1px SOLID #000000;
    background-color: #2a2a2a;
    text-align: center;
    font-weight: bold;
    color: #eee3db;
    font-family: docs-Tahoma, Arial;
    font-size: 10pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s16 {
    border-bottom: 1px SOLID #000000;
    border-right: 1px SOLID #000000;
    background-color: #2a2a2a;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #eee3db;
    font-family: docs-Tahoma, Arial;
    font-size: 10pt;
    vertical-align: middle;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s18 {
    border-bottom: 1px SOLID #000000;
    background-color: #ffffff;
    text-align: left;
    color: #000000;
    font-family: docs-Calibri, Arial;
    font-size: 10pt;
    vertical-align: bottom;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s14 {
    border-bottom: 1px SOLID #000000;
    border-right: 1px SOLID #000000;
    background-color: #2a2a2a;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #eee3db;
    font-family: docs-Tahoma, Arial;
    font-size: 10pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s10 {
    border-bottom: 3px DOUBLE #7f7f7f;
    border-right: 1px SOLID transparent;
    background-color: #ffffff;
    text-align: left;
    color: #000000;
    font-family: Arial;
    font-size: 11pt;
    vertical-align: bottom;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s11 {
    border-bottom: 3px DOUBLE #7f7f7f;
    border-right: 1px SOLID transparent;
    background-color: #ffffff;
    text-align: left;
    font-weight: bold;
    color: #000000;
    font-family: Arial;
    font-size: 11pt;
    vertical-align: bottom;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s1 {
    border-bottom: 1px SOLID #000000;
    background-color: #ffffff;
    text-align: left;
    color: #000000;
    font-family: docs-Tahoma, Arial;
    font-size: 10pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s2 {
    border-bottom: 1px SOLID #000000;
    border-right: 1px SOLID #000000;
    background-color: #6675cf;
    text-align: center;
    font-weight: bold;
    color: #eee3db;
    font-family: docs-Tahoma, Arial;
    font-size: 18pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s15 {
    border-bottom: 1px SOLID #000000;
    border-right: 1px SOLID #000000;
    background-color: #eee3db;
    text-align: center;
    color: #000000;
    font-family: docs-Tahoma, Arial;
    font-size: 10pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s12 {
    border-bottom: 3px DOUBLE #7f7f7f;
    border-right: 1px SOLID transparent;
    background-color: #ffffff;
    text-align: right;
    font-weight: bold;
    color: #000000;
    font-family: Arial;
    font-size: 16pt;
    vertical-align: bottom;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s5 {
    border-bottom: 1px SOLID #000000;
    border-right: 1px SOLID #000000;
    background-color: #eee3db;
    text-align: left;
    color: #000000;
    font-family: docs-Tahoma, Arial;
    font-size: 11pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s8 {
    border-bottom: 1px SOLID #000000;
    border-right: 1px SOLID #000000;
    background-color: #eee3db;
    text-align: left;
    color: #000000;
    font-family: docs-Tahoma, Arial;
    font-size: 10pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s13 {
    border-bottom: 1px SOLID #000000;
    background-color: #ffffff;
    text-align: center;
    color: #000000;
    font-family: docs-Tahoma, Arial;
    font-size: 10pt;
    vertical-align: middle;
    white-space: nowrap;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .signatureCanvas {
    border: 1px solid black;
    width: 100%;
    min-height: 500px;
  }
}

.cover {
  background: #ffffff94;
  height: 100%;
}

.text-top {
  margin-top: 10%;
}

.code {
  text-align: center;
  width: 100%;
  display: block;
  background: lawngreen;
  margin: 1%;
  padding: 1%;
}

#custom-cards:hover .zoomed,
#custom-cards:focus .zoomed {
  -ms-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  border: 1px solid red
}

#custom-cards:hover .zoomed:before,
#custom-cards:focus .zoomed:before {
  display: block;
  border: 1px solid red
}

.expenseForm {
  width: 40%;
}

.expenseForm .row {
  padding: 1%;
}

.newPipeline {
  margin: 1% 0;
  background: aliceblue;
  padding: 1%;
  width: 100%;
  border-radius: 10px;
}

.checkVersion {
  margin: 2%;
  background: bisque;
  padding: 1%;
}

.checkPipeline {
  margin: 2%;
  background: powderblue;
  padding: 1%;
}

.subSelect {
  width: 100%;
  margin-left: 10%;
  display: flex
}

.extendedMode {
  display: flex;
}

.extendedMode .col {
  margin-right: 4%;
}

.darkMode {
  background-color: #212121;
}

.gravvy {
  flex-direction: column !important;
  height: 100% !important;
  display: flex !important
}

.chatBox {
  position: sticky;
  bottom: 0;
  background-color: #212121;
}

.darkMode .chatBox input,
.chatReply {
  font-family: ui-sans-serif, -apple-system, system-ui, Segoe UI, Helvetica, Apple Color Emoji, Arial, sans-serif, Segoe UI Emoji, Segoe UI Symbol;
  font-variation-settings: normal;
  background-color: rgb(48 48 48 / 1);
  width: 80%;
  min-height: 70px;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #353535;
  color: white;
  margin-left: 12px;
  --dark-shadow: 0 0 #0000;
  box-shadow: var(--dark-shadow, 0 0 #0000), var(--dark-shadow, 0 0 #0000), var(--dark-shadow);
  font-size: 1rem;
  line-height: 1.5rem;

  &::placeholder {
    color: #b4b4b4;
  }
}

.darkMode .chatReply {
  margin-top: 20px;
  color: #e9e9e9;
}

.darkMode .chatReply i {
  padding: 1% 0
}

.darkMode .chatReply pre {
  margin-top: 1%;
  font-family: "Open Sans", serif;
  font-optical-sizing: auto;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  white-space: break-spaces;
}